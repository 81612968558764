@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }

  h2 {
    @apply text-3xl;
    @apply font-bold;
  }

  h3 {
    @apply text-2xl;
    @apply font-bold;
  }

  h4 {
    @apply text-xl;
    @apply font-semibold;
  }

  h5 {
    @apply text-lg;
    @apply font-semibold;
  }

  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

@layer utilities {
  .overlay-mask {
    mask-image: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 40%);
  }
}

html,
body {
  overscroll-behavior: none;
}


.realistic-marker-highlight{
  position:relative;
}

.realistic-marker-highlight:before{
  content:"";
  background-color:#ff6db7;
  width:100%;
  height:1em;
  position:absolute;
  z-index:-1;
  filter:url(#marker-shape);
  left:-0.25em;
  top:0.1em;
  padding:0 0.25em;
}

body .wrap {
  background-color: #000000;
  background-image: url(/bg.svg);
}

.mark span::after {
  color: #000;
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
}

.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  display: block;
  margin-right: 30px;
}

html {
  scroll-snap-type: y mandatory;
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}
.moon-phases-container {
  position: relative;
  animation: rotate 60s linear infinite; /* Adjust the duration as needed */
}

.moon-phase {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 4rem;
  color: #DDDACD;
  font-weight: bold;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
